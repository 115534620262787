.swipe-to-scroll {
    animation: ease 5000ms swipe-to-scroll infinite;
}

@keyframes swipe-to-scroll {
    0% {
        transform: translateX(-40%);
    }

    50% {
        transform: translateX(40%);
    }

    100% {
        transform: translateX(-40%);
    }
}
