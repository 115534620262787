.localized-text > p:not(:last-child) {
    /*margin-bottom: 1rem;*/
}

.localized-text.inline > p {
    display: inline;
}

.localized-text > ul > br {
    display: none;
}
